import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("/vercel/path0/layouts/default.vue")),
  error: defineAsyncComponent(() => import("/vercel/path0/layouts/error.vue")),
  guest: defineAsyncComponent(() => import("/vercel/path0/layouts/guest.vue")),
  legal: defineAsyncComponent(() => import("/vercel/path0/layouts/legal.vue")),
  none: defineAsyncComponent(() => import("/vercel/path0/layouts/none.vue")),
  playback: defineAsyncComponent(() => import("/vercel/path0/layouts/playback.vue")),
  room: defineAsyncComponent(() => import("/vercel/path0/layouts/room.vue")),
  settings: defineAsyncComponent(() => import("/vercel/path0/layouts/settings.vue"))
}