import revive_payload_client_APDjpMmLtQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ISW9AH9nNi from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_m74nOnNwQx from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RsWSqDb3jM from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ckHRjuudcf from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_KGF7rniJxx from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sODEEjTGPD from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_S6b3LWowKI from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.4_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_X5zqSS9iEA from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LqwtDNIB51 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.27.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import theme_client_Vmk3lWTdSS from "/vercel/path0/node_modules/.pnpm/@una-ui+nuxt-edge@0.30.0-beta.1-28880710.a2fc753_@unocss+preset-wind@0.64.1_@unocss+webpack@0_lome4ucbmnovtd5wtcrl3yl6dy/node_modules/@una-ui/nuxt-edge/dist/runtime/plugins/theme.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
export default [
  revive_payload_client_APDjpMmLtQ,
  unhead_ISW9AH9nNi,
  router_m74nOnNwQx,
  payload_client_RsWSqDb3jM,
  navigation_repaint_client_ckHRjuudcf,
  check_outdated_build_client_KGF7rniJxx,
  chunk_reload_client_sODEEjTGPD,
  plugin_vue3_S6b3LWowKI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_X5zqSS9iEA,
  plugin_client_LqwtDNIB51,
  unocss_MzCDxu9LMj,
  theme_client_Vmk3lWTdSS,
  analytics_client_mtqVw3YRXk
]